<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Garante - Ver</strong>

            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/garante/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off">

              <b-row>
                <b-col md="2">
                  <b-form-group label="Tipo de Documento :">
                    <b-form-select disabled v-model="guarantor.document_type" :options="document_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Documento :">
                    <b-input-group>
                      <b-form-input disabled v-model="guarantor.document_number" class="form-control" ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Nombres :">
                    <b-form-input type="text" disabled v-model="guarantor.name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Nacimiento:">
                    <b-form-input type="date" disabled v-model="guarantor.birth_date"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Ubigeo:">
                    <v-select placeholder="Seleccione un ubigeo" label="text" class="w-100" v-model="mubigee" disabled></v-select>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Dirección :">
                    <b-form-input type="text" disabled  v-model="guarantor.address"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Email :">
                    <b-form-input type="email" disabled  v-model="guarantor.email"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Celular :">
                    <b-form-input type="text" disabled  v-model="guarantor.cell_phone"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Observación:">
                    <b-form-textarea type="text" disabled v-model="guarantor.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select disabled v-model="guarantor.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

         
              </b-row>
              
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>



    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import ApiQuery from "@/assets/js/APIQuery";
import CodeToName from "@/assets/js/CodeToName";
// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_guarantor"],
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Guarantor',
      role: 5,
      guarantor: {
        id_client:'',
        id_branch_office:'',
        document_type:1,
        document_number:'',
        birth_date:'',
        name:'',
        cell_phone:'',
        email:'',
        address:'',
        observation:'',
        state:1,
      },
      mubigee: null,
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      error_document_number:'',
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],

  
    };
  },
  mounted() {
    this.ViewGuarantor();
  },
  methods: {
    ViewGuarantor,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ViewGuarantor() {
  let me = this;
  let id_guarantor = je.decrypt(this.id_guarantor);
  let url = me.url_base + "guarantor/view/" + id_guarantor;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  }).then(function (response) {
      if (response.data.status == 200) {
        me.guarantor.id_guarantor = response.data.result.id_guarantor;
        me.guarantor.id_branch_office = response.data.result.id_branch_office;
        me.guarantor.document_type = response.data.result.document_type;
        me.guarantor.document_number = response.data.result.document_number;
        me.guarantor.name = response.data.result.name;
        me.guarantor.birth_date = response.data.result.birth_date;
        me.guarantor.cell_phone = response.data.result.cell_phone;
        me.guarantor.email = response.data.result.email;
        me.guarantor.address = response.data.result.address;
        me.guarantor.observation = response.data.result.observation;
        me.guarantor.state = response.data.result.state;
        if (response.data.result.ubigee.length > 0) {
          me.mubigee = {value:response.data.result.ubigee,text:response.data.result.ubigee_value }  
        }

      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000, })
      }
      me.isLoading = false;
  }).catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000, })
      me.isLoading = true;
  });
}



</script>
